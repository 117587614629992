.no-expenses-container {
  display: grid;
}

.no-expenses-error-icon {
  margin: auto;
  width: 9rem;
}

.no-expenses-text-container {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}

.no-expenses-p-margin {
  margin-top: 0.5rem;
}
