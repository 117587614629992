body {
  padding: 0 !important;
}

button {
  background: none;
  border: none;
  padding: 0;
}

.share-header {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-group {
  white-space: nowrap;
  margin-top: 0.5rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 1000rem;
  margin: 0 0.45rem 0 0.45rem;
}

.icon-name {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  max-width: 0px;
  transition: max-width 1s, margin 0.3s;
  font-size: 0.88rem;
  cursor: pointer;
  color: #fff;
}

.share-text-container {
  display: flex;
  justify-content: flex-end;
}

.feedback-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgb(176, 175, 182);
  gap: 1rem;
}

.feedback-button-and-text {
  color: var(--hover-color);
  border: 1px solid var(--hover-color);
  padding: 0.3125rem 1rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: none;
}

.feedback-button-and-text:hover {
  color: var(--primary-color);
  background-color: var(--hover-color);
  border: 1px solid var(--hover-color);
}

.button-no-format {
  cursor: pointer;
}

@media (min-width: 601px) {
  .icon:hover > .icon-name {
    max-width: 130px;
    margin-left: 0.3rem;
  }

  button:focus-visible > .icon > .icon-name {
    max-width: 130px;
    margin-left: 0.3rem;
  }
}

@media (max-width: 700px) {
  .container {
    width: 80%;
  }

  .icons {
    flex-wrap: wrap;
  }

  .share-text-container {
    justify-content: start;
  }

  .icon {
    margin: 0 0.15rem;
  }
}
