.filter-section-container {
  margin-bottom: 2rem;
}

.arrow-margin {
  margin-left: 1rem;
}

.results-section-header {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.citizenship-button.MuiButton-root {
  background-color: #ffff;
  color: var(--primary-color);
  border-radius: 1.5625rem;
  border: 1px solid var(--primary-color);
}

.flat-white-border-bottom.MuiButton-root {
  border-radius: 1.5625rem 1.5625rem 0 0;
  border-bottom: #ffff;
  background-color: #ffff;
}

.flat-white-border-bottom.MuiButton-root::after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: #ffff;
  z-index: 10000;
  position: absolute;
  bottom: 0;
}

.active-blue.MuiButton-root {
  background-color: rgba(65, 82, 140, 0.14);
}

.reset-button.MuiButton-root {
  background-color: #ffff;
  color: var(--primary-color);
  border: none;
  font-weight: 400;
}

.subcategory-indentation.MuiFormControlLabel-root {
  margin-left: 1rem;
}

#citizenshipPopover .MuiPaper-root.MuiPopover-paper {
  border-radius: 0 1.5625rem 1.5625rem 1.5625rem;
  width: 21rem;
  border: 1px solid var(--primary-color);
  box-shadow: none;
  box-sizing: content-box;
  max-height: 50vh;
}

.filters-container {
  padding: 0 0.75rem 0.75rem 0.75rem;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.MuiFormControlLabel-root.vertical-align {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
}

.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0 0.5rem;
}

.filters-close-button {
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem 0.75rem;
}

@media (max-width: 500px), (max-height: 700px) {
  .MuiPaper-root.MuiPopover-paper {
    max-height: 24rem;
    overflow-y: scroll;
  }

  .filter-ref-container {
    max-height: 18rem;
  }
}
