.results-loading-container {
  flex: 1;
}

.results-tab-container {
  background-color: var(--primary-color);
  padding: 1rem 1rem 0 1rem;
}

.results-tab {
  color: var(--primary-color);
  font-family: 'Open Sans', sans-serif;
  border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  font-weight: bold;
}

.results-tab a {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: 0.5rem;
  text-decoration: none;
  background-color: #e6e7e8;
  color: var(--primary-color);
  border-radius: 1rem 1rem 0 0;
}

.results-tab a.active {
  background-color: white;
}

.info-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: var(--primary-color);
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  color: white;
  font-weight: bold;
}

.info-circle:hover {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.category-heading-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.category-heading-icon {
  display: inline-flex;
}

.category-heading-icon svg {
  display: inline-flex;
  width: 2.5rem;
}

.category-heading-icon svg path {
  fill: var(--icon-color);
}

.category-heading-column {
  min-width: 0;
  max-width: 100%;
  width: fit-content;
  text-align: left;
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}

.category-heading-text-style {
  display: inline-flex;
  margin-left: 0.25rem;
  align-self: center;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  color: var(--secondary-color);
}

.category-heading-text-style.normal-weight {
  font-weight: normal;
}

.results-header-container {
  height: 9rem;
}

.results-header {
  height: 7rem;
}

.results-needs-header-background {
  background-color: var(--secondary-background-color);
  padding: 1rem;
}

.results-needs-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 7rem;
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  line-height: normal;
}

.results-needs-header-programs {
  display: flex;
  justify-content: center;
  font-size: xxx-large;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
}

.results-needs-header-programs-text {
  display: flex;
  width: 8rem;
  font-size: large;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin: auto;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.child-care-warning-text {
  background-color: var(--secondary-background-color);
  font-size: 1.25rem;
  color: var(--primary-color);
  padding: 0.5rem;
}

.result-program-container {
  border: 2px solid var(--secondary-color);
  margin-bottom: 1rem;
}

.result-program-container hr {
  background-color: var(--secondary-color);
  width: 98%;
  margin: 0 auto;
  height: 2px;
  border: none;
}

.result-program-more-info {
  width: 70%;
  display: block;
  line-height: normal;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  color: var(--primary-color);
}

.result-program-more-info-button {
  line-height: normal;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 1.25rem;
  margin: auto;
  color: var(--primary-color);
}

.result-program-more-info-button a {
  display: flex;
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  border-radius: 0.75rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.result-program-details {
  font-size: 0.9rem;
  flex-grow: 1;
  flex-direction: column;
  font-weight: 100;
  line-height: normal;
  width: 50%;
  padding: 1rem 2rem;
  color: var(--primary-color);
}

.result-program-details-box {
  display: flex;
}

.result-program-details strong {
  display: block;
}

.result-program-subheader {
  font: Open Sans, sans-serif;
}

.child-care-warning-text {
  background-color: var(--secondary-background-color);
  font-size: 1.25rem;
  color: var(--primary-color);
  padding: 0.5rem;
}
/* the following two result-program styles are for the more-info buttons on the long-term pg */
.result-program-more-info-wrapper {
  display: flex;
  flex-direction: row;
}

.result-program-more-info-button a:hover {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 0.75rem;
}

/* DESKTOP */
@media (min-width: 775px) {
  .results-header-programs-count-text {
    height: 3rem;
    border-radius: 0.5rem;
    background-color: var(--primary-color);
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }

  .results-data-cell {
    display: flex;
    flex-direction: column-reverse;
    margin: 0.5rem 1rem;
  }

  .column-row {
    display: flex;
    flex-direction: row;
  }

  .results-header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .results-header-programs-count {
    font-size: x-large;
    font-weight: 700;
    margin: auto;
    font-family: 'Roboto Slab', serif;
    display: flex;
    margin-right: 0.5rem;
  }

  .results-header-values {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: var(--primary-color);
    border-top: 1.5px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
  }

  .results-header-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: var(--primary-color);
  }

  .result-program-container {
    display: flex;
    align-items: center;
  }

  .result-program-more-info {
    width: 40%;
    vertical-align: top;
    line-height: normal;
  }

  .result-program-details {
    font-weight: 400;
    width: 20%;
    vertical-align: text-bottom;
    color: var(--midBlue-color);
  }

  .result-program-container hr {
    display: none;
  }

  .result-program-more-info a {
    padding: 0.5rem 1rem;
  }

  .result-program-details strong {
    display: inline-block;
    color: var(--primary-color);
  }
}

/* Please keep all media queries at the end of the file so that the styles cascade correctly */
/* TABLET */
@media only screen and (min-width: 481px) and (max-width: 775px) {
  .results-header-programs-count-text {
    width: 7rem;
    height: 7rem;
    background-color: var(--primary-color);
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding-top: 0.75rem;
    line-height: normal;
    padding-bottom: 0.5rem;
    margin-right: 1rem;
  }

  .column-row {
    display: flex;
    flex-direction: column;
  }

  .results-header {
    display: flex;
    flex-direction: row;
  }

  .results-header-programs-count {
    font-size: xx-large;
    font-weight: 700;
    padding-bottom: 0.25rem;
    font-family: 'Roboto Slab', serif;
  }

  .results-header-values {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1.5px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
  }

  .results-header-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: var(--primary-color);
  }

  .results-header {
    display: flex;
    flex-direction: row;
  }

  .result-program-more-info {
    width: 20%;
    vertical-align: top;
    line-height: normal;
  }
}

/* MOBILE */
@media (max-width: 480px) {
  .results-header-programs-count-text {
    width: 7rem;
    height: 7rem;
    background-color: var(--primary-color);
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding-top: 0.75rem;
    line-height: normal;
    padding-bottom: 0.5rem;
    margin-right: 1rem;
  }

  .column-row {
    display: flex;
    flex-direction: column;
  }

  .results-header {
    display: flex;
    flex-direction: row;
  }

  .results-header-programs-count {
    font-size: xx-large;
    font-weight: 700;
    padding-bottom: 0.25rem;
    font-family: 'Roboto Slab', serif;
  }

  .results-header-values {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1.5px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
  }

  .results-header-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: var(--primary-color);
  }

  .results-header {
    display: flex;
    flex-direction: row;
  }
}
